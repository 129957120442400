import * as React from 'react';
import Layout from '../components/Layout.js';
import Links from '../components/links/Links.js';

const BannersPage = () => {
  return (
    <Layout title={{ black: 'Configure', blue: 'banners' }}>
      <Links>
        <Links.Link
          href="/banners/sports"
          title="Sport Banners"
          text="Configure advertising banners for sports."
        />
        <Links.Link
          href="/banners/competitions"
          title="Competition Banners"
          text="Configure advertising banners for competitions."
        />
        <Links.Link
          href="/banners/teams"
          title="Team Banners"
          text="Configure advertising banners for teams."
        />
        <Links.Link
          href="/banners/events"
          title="Event Banners"
          text="Configure advertising banners for events."
        />
      </Links>
    </Layout>
  );
};

export default BannersPage;
